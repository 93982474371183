<template>
  <crud-form
    :schema="schema"
    :model="form"
    :model-id="id"
    :path="'users-alerts'"
    :form-title="$t('editUserAlert')"
    :redirect-route="'prev'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { form } from './forms/user-alert'
  import { schema } from './schema/user-alert'

  export default {
    name: 'UpdateUserAlert',
    components: {
      CrudForm
    },
    props: ['id'],
    data: () => {
      return {
        schema,
        form,
      }
    },
  }
</script>
